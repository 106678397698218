import './App.css';
import { useEffect } from 'react';
import { useEMailStore, useDark } from './hook';
import Header from './components/Header';
import Main from './components/Main'
import { cn } from './utils';

function App() {
  const { tempEMailAddress, emails } = useEMailStore();
  const [isDark, setIsDark] = useDark()
  useEffect(() => {
    // eslint-disable-next-line
  }, [])
  return (
    <div className={cn(isDark?"":"light" ,"w-screen h-screen overflow-hidden px-4 pb-4 text-foreground bg-background transition duration-300")}>
      <div className='max-w-screen-2xl h-full mx-auto grid grid-rows-[auto,calc(100%-64px)]'>
        <Header isDark={isDark} setIsDark={setIsDark} />
        <Main tempEMailAddress={tempEMailAddress} emails={emails}/>
      </div>
    </div>
  );
}

export default App;

import { useContext } from "react";
import { EMailContext } from "../providers/email-provider";
import { useState, useEffect } from "react";

export const useEMailStore = () => useContext(EMailContext);

export const useDark = () => {
  // 使用 window.matchMedia() 来检测系统的颜色主题偏好
  const [isDark, setIsDark] = useState(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
  useEffect(() => {
    if (window.matchMedia) {
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
        setIsDark(event.matches);
        // document.documentElement.className = event.matches ? "" : "light";
      });
      // document.documentElement.className = isDark ? "" : "light";
    }
  // eslint-disable-next-line
  }, [])
  useEffect(() => {
    // document.documentElement.className = isDark ? "" : "light";
  })
  return [isDark, setIsDark];
}

import { Switch } from "./ui/switch";
import { Mailbox,Moon,Sun } from "lucide-react";

export default function Header({isDark, setIsDark}) {
  return (
    <header className="w-full h-16 flex justify-between items-center">
      <div className="flex items-center gap-2">
        <Mailbox size={24} />
        <p className="font-bold text-xl tracking-tighter">
          Temp Email
        </p>
      </div>
      <div className="flex items-center gap-2">
        <Switch checked={isDark} onCheckedChange={setIsDark} id="darkSwitch" />
        <label htmlFor="darkSwitch" className="font-bold">
          {
            isDark ? (
              <Moon size={20} />
            ) : (
              <Sun size={20} />
            )
          }
        </label>
      </div>
    </header>
  )
}
import { toast } from "sonner";

class OFetch {
  #baseURL = process.env.REACT_APP_API_BASE_URL + "/api";
  #objectToQueryString(queryObj) {
    if (!queryObj)
      return "";
    return Object.entries(queryObj).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join("&");
  }
  #resInterceptor(res) {
    if (res.code !== 200) {
      return Promise.reject(res);
    }
    return Promise.resolve(res.data ?? res);
  }
  async get(url, query) {
    const requestUrl = `${this.#baseURL}${url}?${this.#objectToQueryString(query)}`
    try {
      return await this.#resInterceptor(await(await fetch(requestUrl)).json());
    } catch (e) {
      console.error(`request "${requestUrl}" ERROR`, e);
      toast(e.message);
    }
  }
  async post(url, body) {
    return await(await fetch(`${this.#baseURL}${url}`, {
      method: "POST",
      body: JSON.stringify(body),
    })).json();
  }
}

export const ofetch = new OFetch();

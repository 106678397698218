import { ofetch } from '../utils';

const api = {
  async getNewTempEMail() {
    return await ofetch.get("/tempemail/new");
  },
  async getTempEMailInfo(address) {
    return await ofetch.get(`/tempemail/info/${address}`);
  },
  /**
   * 续期邮箱地址
   * @param {string} address 邮箱地址
   */
  async renewalTempEmail(address) {
    return await ofetch.get(`/tempemail/renewal/${address}`);
  },
  async getEmails(address) {
    return await ofetch.get(`/tempemail/emails/${address}`);
  },
}

export default api;

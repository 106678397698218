import { AtSign, Siren, RefreshCw, Inbox, Copy, Trash2, SquareDashedMousePointer } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "./ui/alert"
import { Button } from "./ui/button"
import { useState } from "react"
import { ScrollArea } from "../components/ui/scroll-area"
import { cn } from "../utils";

export default function Main({ tempEMailAddress, emails }) {
  const [showEmail, setShowEmail] = useState(null);
  const handleChooseMail = (email) => {
    setShowEmail(email);
  }
  return (
    <main className="h-full grid grid-cols-[400px,auto,1fr] border rounded-xl">
      <section className="h-full overflow-auto">
        <div className="p-4 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <AtSign size={20} />
            <p className="font-bold">{tempEMailAddress ?? "loading..."}</p>
          </div>
          <div>
            <Button variant="ghost" size="icon" className="rounded-xl" disabled>
              <Copy size={16} />
            </Button>
          </div>
        </div>
        <div className="h-px bg-border"></div>
        <div className="p-4 flex justify-between items-center border-b">
          <div className="flex items-center gap-2">
            <Inbox size={20} />
            <h1 className="text-xl font-bold">Inbox</h1>
          </div>
          <Button variant="ghost" size="icon" className="rounded-xl">
            <RefreshCw size={16} />
          </Button>
        </div>
        {
          emails.length === 0 ? (
            <div className="h-[calc(100%-146px)]">
              <div className="h-full flex gap-2 justify-center items-center">
                <RefreshCw size={20} className="animate-spin" />
                <p className="font-bold animate-pulse">
                  Receiving emails...
                </p>
              </div>
            </div>
          ) : (
            <ScrollArea className="h-[calc(100%-146px)] p-4">
              {
                emails.map((email, index) => (
                  <div key={index} className="py-1" onClick={() => handleChooseMail(email)}>
                    <div className={cn(showEmail?.messageId===email?.messageId&&"bg-muted","p-4 border rounded-xl hover:bg-muted transition")}>
                      <div className="flex justify-between items-center">
                        <p className="font-bold">{email?.from?.name ?? ""}</p>
                        <p className="text-xs text-muted-foreground">{email?.date ? new Date(email.date).toLocaleString() : ""}</p>
                      </div>
                      <p className="mt-1 text-xs font-bold">{email?.subject ?? ""}</p>
                      <p className="line-clamp-2 text-muted-foreground mt-2 text-xs">{email?.text ?? ""}</p>
                    </div>
                  </div>
                ))
              }
            </ScrollArea>
          )
        }
      </section>
      <div className="w-px bg-border"></div>
      <section className="grid grid-rows-[calc(100%-126px),auto] overflow-hidden">
        <section className="overflow-hidden">
          {
            showEmail ? (
              <>
                <div className="p-4">
                  <Button variant="ghost" size="icon" className="rounded-xl">
                    <Trash2 size={16} />
                  </Button>
                </div>
                <div className="h-px bg-border"></div>
                <div className="p-4">
                  <div className="flex justify-between">
                    <div>
                      <p className="text-lg font-bold">{showEmail.from.name}</p>
                      <p className="mt-1">From: &lt;{showEmail.from.address}&gt;</p>
                    </div>
                    <div>
                      <p className="text-sm text-muted-foreground">{new Date(showEmail.date).toLocaleString()}</p>
                    </div>
                  </div>
                </div>
                <div className="h-px bg-border"></div>
                <ScrollArea className="h-[calc(100%-160px)] p-4">
                  <h2 className="text-lg font-bold">{showEmail?.subject ?? ""}</h2>
                  <div className="mt-2 text-pretty whitespace-pre-wrap">
                    {showEmail?.text ?? ""}
                  </div>
                </ScrollArea>
              </>
            ) : (
              <div className="h-full flex justify-center items-center gap-4">
                <SquareDashedMousePointer size={40} />
                <p className="text-xl font-bold tracking-tighter">EMPTY</p>
              </div>
            )
          }
        </section>
        <section className="p-4">
          <Alert className="rounded-xl">
            <Siren size={16} />
            <AlertTitle className="font-bold">Heads up!</AlertTitle>
            <AlertDescription className="">
              此电子邮件地址在此页面打开后7天内有效。
            </AlertDescription>
            <AlertDescription className="">
              This email address is valid for 7 days after opening on this page.
            </AlertDescription>
          </Alert>
        </section>
      </section>
    </main>
  )
}

import Cookies from "js-cookie";
import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export * from "./ofetch";
export * from "./ows";

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

const KEY_COOKIE_TEMP_EMAIL_ADDRESS = "TEMP_EMAIL_ADDRESS"
const EXPIRATIONTTL_COOKIE_TEMP_EMAIL_ADDRESS = 7;
export const getCookieTempEMailAddress = () => {
  return Cookies.get(KEY_COOKIE_TEMP_EMAIL_ADDRESS) ?? null;
}
export const saveCookieTempEMailAddress = (tempEMailAddress) => {
  Cookies.set(KEY_COOKIE_TEMP_EMAIL_ADDRESS, tempEMailAddress, { expires: EXPIRATIONTTL_COOKIE_TEMP_EMAIL_ADDRESS });
}

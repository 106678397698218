import { createContext, useEffect, useState } from "react";
import api from "../api";
import { getCookieTempEMailAddress, saveCookieTempEMailAddress } from "../utils";

export const EMailContext = createContext({});

const EMailProvider = ({ children }) => {
  const [tempEMailAddress, setTempEMailAddress] = useState(null);
  const [emails, setEMails] = useState([]);

  useEffect(() => {
    let interval;
    ;(async () => {
      let cookieTempEMailAddress = getCookieTempEMailAddress();
      if (cookieTempEMailAddress === null) {
        const { tempEMailAddress } = await api.getNewTempEMail();
        setTempEMailAddress(tempEMailAddress);
        saveCookieTempEMailAddress(tempEMailAddress);
        cookieTempEMailAddress = tempEMailAddress;
      } else {
        // 获取邮箱账号信息
        const _emails = JSON.parse((await api.getTempEMailInfo(cookieTempEMailAddress))?.info?.emails??"");
        setTempEMailAddress(cookieTempEMailAddress);
        setEMails(_emails);
        // 续费临时邮箱时间, 本地cookie和D1数据库都要更新
        const { msg } = await api.renewalTempEmail(cookieTempEMailAddress);
        console.log(`renewal ${msg}`);
        saveCookieTempEMailAddress(cookieTempEMailAddress);
      }
      // 循环刷新
      interval = setInterval(async () => {
        const { emails } = await api.getEmails(cookieTempEMailAddress);
        setEMails(() => {
          return JSON.parse(emails);
        })
      }, 15000);
    })();
    return () => {
      clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    console.log("emails update", emails);
  }, [emails]);

  const values = { tempEMailAddress, setTempEMailAddress ,emails, setEMails };

  return <EMailContext.Provider value={values} >{ children }</EMailContext.Provider>
}

export default EMailProvider;
